/**
 * 聊天模块的ajax函数
 */
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 查询聊天列表
export const searchRecentChatUser = (params) => {
  return request({
    url: '/api/chat/searchRecentChatUser',
    method: "get",
    params
  })
}

// 查询聊天记录
export const searchUserChatHis = (params) => {
  return request({
    url: '/api/chat/searchUserChatHis',
    method: "get",
    params
  })
}