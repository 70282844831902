<template>
  <div class="im_wrapper">
    <div class="mask">
      <div class="im_box">
        <!-- 左侧联系人列表 -->
        <div class="list_wrapper">
          <div class="search">
            <el-input
              placeholder="搜索最近联系人"
              v-model="searchForm.name"
              @keyup.enter.native="searchResults"
              clearable
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>

            <div class="search_suggest scrollbar" v-if="searchForm.name">
              <div
                class="suggest_item"
                v-for="(item, index) in searchResultsList"
                :key="'suggest' + index"
                @click="handleChooseResult(item)"
              >
                {{
                  item.fromUserid == userInfo.id
                    ? item.toUser.nickname
                    : item.fromUser.nickname
                }}
              </div>

              <el-empty
                v-if="searchResultsList.length == 0"
                image="https://www.zizhihelp.com/upload/static/web/no_search.png"
                description="暂无搜索结果"
                :image-size="80"
              ></el-empty>
            </div>
          </div>

          <div class="chat_list scrollbar" @scroll="handleLeftScroll">
            <!-- 列表 -->
            <div
              @click="selectChat(index, item)"
              :class="['list_item', index == activeIndex ? 'active' : '']"
              v-for="(item, index) in list"
              :key="'list' + index"
              :id="'list' + index"
            >
              <div>
                <el-badge :value="item.newNum" :max="99" class="item">
                  <img :src="item.avatarUrl" class="avatar" alt="" />
                </el-badge>
              </div>

              <div class="right_box">
                <div class="name_box">
                  <div class="name singleLine">
                    {{
                      item.fromUserid == userInfo.id
                        ? item.toUser.nickname
                        : item.fromUser.nickname
                    }}
                  </div>
                  <div class="time">{{ item.time }}</div>
                </div>
                <div class="msg_box singleLine">
                  {{
                    item.msgType == "0"
                      ? item.msg
                      : item.msgType == "1"
                      ? "[图片]"
                      : ""
                  }}
                </div>
              </div>
            </div>

            <!-- 加载更多 -->
            <div class="tac c999 fz14" v-if="list.length > 0">
              <span v-if="leftLoadStatus == 'loadmore'" @click="getList(true)">
                点击加载更多
              </span>

              <i
                class="el-icon-loading fz16"
                v-if="leftLoadStatus == 'loading'"
              ></i>

              <span v-if="leftLoadStatus == 'nomore'">-- 没有更多了 --</span>
            </div>

            <!-- 空数据 -->
            <el-empty
              v-if="list.length == 0"
              image="https://www.zizhihelp.com/upload/static/web/noRecord.png"
              description="暂无记录"
            ></el-empty>
          </div>
        </div>

        <!-- 右侧聊天窗口 -->
        <div class="chat_wrapper" v-if="list.length > 0">
          <!-- 聊天对象的nickname -->
          <div class="chat_name">
            <div>{{ msgUsr.nickname }}</div>
          </div>

          <!-- 消息列表 -->
          <div
            class="msg_wrapper scrollbar"
            ref="right"
            @scroll="handleMsgScroll"
          >
            <div class="tac fz16" v-if="loading">
              <i class="el-icon-loading"></i>
            </div>

            <div
              :class="['msg_item', index == 0 ? 'pt15' : '']"
              v-for="(item, index) in msgList"
              :key="'msg' + index"
              :id="'msg' + item.id"
            >
              <!-- b - 对方的消息  -->
              <div
                class="content_wrapper content_wrapper_left"
                v-if="item.fromUserid !== userInfo.id"
              >
                <!-- 头像 -->
                <img
                  :src="item.fromUser.headimgurl || avatar"
                  class="avatar mr10"
                />
                <!-- 0 - 文字 -->
                <div class="chat_content chat_content_left">
                  <div class="name_wrapper">
                    <div class="name singleLine">
                      {{ item.fromUser.nickname }}
                    </div>
                    <div class="time">{{ item.msgTime }}</div>
                  </div>

                  <div class="msg" v-if="item.msgType === '0'">
                    {{ item.msg }}
                  </div>

                  <div class="msg" v-if="item.msgType === '1' && item.msg">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="item.msg"
                      :preview-src-list="[item.msg]"
                    >
                    </el-image>
                  </div>
                </div>
              </div>

              <!--a - 自己的信息-->
              <div
                class="content_wrapper content_wrapper_right"
                v-if="item.fromUserid === userInfo.id"
              >
                <div class="chat_content chat_content_right">
                  <div class="name_wrapper">
                    <div class="name singleLine">
                      {{ item.fromUser.nickname }}
                    </div>
                    <div class="time">{{ item.msgTime }}</div>
                  </div>
                  <!-- 0 - 文字 -->
                  <div class="msg" v-if="item.msgType === '0'">
                    {{ item.msg }}
                  </div>
                  <!-- 1 - 图片 -->
                  <div class="msg" v-if="item.msgType === '1' && item.msg">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="item.msg"
                      :preview-src-list="[item.msg]"
                    >
                    </el-image>
                  </div>
                </div>

                <!-- 头像 -->
                <img
                  :src="item.fromUser.headimgurl || avatar"
                  class="avatar ml10"
                />
              </div>
            </div>

            <el-empty
              v-if="msgList.length == 0"
              image="https://www.zizhihelp.com/upload/static/web/icon_talk.png"
              description="开始聊天吧！"
              :image-size="100"
            ></el-empty>
          </div>

          <!-- 输入框 -->
          <div class="input_wrapper">
            <el-input
              type="textarea"
              :rows="4"
              resize="none"
              placeholder="请输入消息，按Enter或点击发送按钮发送"
              v-model="msg"
              maxlength="500"
              show-word-limit
              @keyup.enter.native="sendMsg"
            >
            </el-input>

            <div class="tar pr20 pb20">
              <div class="send_btn" @click="sendMsg">发送</div>
            </div>
          </div>
        </div>

        <!-- 左右数据均为空时显示 -->
        <div class="chat_wrapper alc jcc" v-if="list.length == 0">
          <el-empty
            image="https://www.zizhihelp.com/upload/static/web/icon_talk.png"
            description="开始聊天吧！"
            :image-size="100"
          ></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { searchRecentChatUser, searchUserChatHis } from "@/api/chat.js"; // 引入接口文件
import local from "@/utils/local.js"; // 引入本地存储工具

export default {
  data() {
    return {
      avatar: "https://www.zizhihelp.com/upload/zwtx.png", // 默认头像
      msg: "", // 输入框内容

      /* 左侧列表 */
      // 搜索参数
      searchForm: {
        name: "", // 姓名
        pageNum: 1,
        pageSize: 50,
      },
      list: [], // 左侧列表数据
      activeIndex: 0, // 当前激活的左侧列表索引
      leftLoadStatus: "loadmore", // 左侧加载状态
      searchResultsList: [], // 搜索结果列表数据
      searchLoading: false, // 搜索加载状态

      /* 右侧窗口 */
      // 聊天记录搜索参数
      msgSearchForm: {
        pageNum: 1,
        pageSize: 20,
      },
      msgList: [], // 右侧聊天记录数据
      myInfo: {}, // 我的用户信息，用于发送消息时携带
      loading: false, // 聊天历史记录的加载状态
      msgId: "", // 当前聊天记录的最后一条消息id，用于加载更多时使用
    };
  },

  computed: {
    // 本地的当前用户信息
    userInfo() {
      const userInfo = local.get("userInfo") || { id: null };
      return userInfo;
    },
    // 当前聊天对象信息
    msgUsr() {
      let temp = this.list[this.activeIndex] || {};
      if (temp.id) {
        if (temp.fromUserid == this.userInfo.id) {
          this.myInfo = temp.fromUser;
          return temp.toUser;
        } else {
          this.myInfo = temp.toUser;
          return temp.fromUser;
        }
      } else {
        this.myInfo = {};
        return {};
      }
    },
  },

  watch: {
    // 监听聊天对象变化，重新获取聊天记录数据
    msgUsr: {
      handler(newVal) {
        if (newVal.id) this.msgList = this.list[this.activeIndex].recentMsgList;
        this.scrollToBottom();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    /* 获取数据 */
    // 获取左侧列表数据 flag: 是否追加数据
    async getList(flag) {
      if (this.leftLoadStatus != "loadmore") return;

      this.leftLoadStatus = "loading";
      if (flag) {
        this.searchForm.pageNum++;
      } else {
        this.searchForm.pageNum = 1;
      }
      const params = { ...this.searchForm, wxUserId: this.userInfo.id };

      let { data } = await searchRecentChatUser(params);
      let { records } = data;
      if (records.length > 0) {
        this.list = this.list.concat(this.processListData(records));
      }
      if (records.length == 0 || this.list.length < this.searchForm.pageSize) {
        this.leftLoadStatus = "nomore";
      } else {
        this.leftLoadStatus = "loadmore";
      }
    },
    // 处理左侧列表数据 arr: 原数据数组
    processListData(arr) {
      if (arr.length > 0) {
        arr.forEach((v) => {
          v.time = v.msgTime.split(" ")[0];
          v.newNum = null;
          if (v.fromUserid == this.userInfo.id) {
            v["avatarUrl"] =
              v.toUser.headimgurl ||
              "https://www.zizhihelp.com/upload/zwtx.png";
          } else {
            v["avatarUrl"] =
              v.fromUser.headimgurl ||
              "https://www.zizhihelp.com/upload/zwtx.png";
          }

          v.recentMsgList = v.recentMsgList.reverse();
        });
        return arr;
      } else {
        return [];
      }
    },
    // 获取右侧聊天数据 flag: 是否追加数据
    async getChat(flag) {
      if (this.loading) return;

      this.loading = true;
      if (flag) {
        this.msgSearchForm.pageNum++;
      } else {
        this.msgSearchForm.pageNum = 1;
      }
      let params = {
        ...this.msgSearchForm,
        toUserId: this.msgUsr.id,
        wxUserId: this.userInfo.id,
      };

      let { data } = await searchUserChatHis(params);
      let { records } = data;

      if (flag) {
        this.msgList = [...records.reverse(), ...this.msgList];
      } else {
        this.msgList = records.reverse();
      }

      if (!flag) {
        this.scrollToBottom();
      } else {
        this.scrollToView(`msg${this.msgId}`);
      }
      this.loading = false;
    },

    // 获取搜索结果
    async searchResults() {
      this.searchLoading = true;
      let params = { ...this.searchForm, wxUserId: this.userInfo.id };
      params.pageNum = 1;
      let { data } = await searchRecentChatUser(params);
      let { records } = data;
      this.searchResultsList = this.processListData(records);
      this.searchLoading = false;
    },

    /* 操作 */
    // 左侧搜索--选择搜索结果
    handleChooseResult(item) {
      // this.searchResults();
      console.log("item>>>", item);
      this.searchForm.name = "";
    },
    // 左侧滚动条滑动
    handleLeftScroll(element) {
      let isBottom = this.isScrollAtBottom(element.target);

      if (isBottom) {
        this.getList(true);
      }
    },
    // 判断是否滚动到底部
    isScrollAtBottom(element) {
      return (
        element.scrollHeight - element.clientHeight - element.scrollTop <= 10
      );
    },
    // 点击左侧列表项
    selectChat(index) {
      this.activeIndex = index;
      this.list[index].newNum = null;
      this.delStorage();
    },
    // 消息列表滚动条滑动
    handleMsgScroll(element) {
      let isTop = this.isScrollAtTop(element.target);

      if (isTop) {
        this.msgId = this.msgList[0].id;
        this.getChat(true);
      }
    },
    // 判断是否滚动到顶部
    isScrollAtTop(element) {
      return element.scrollTop === 0;
    },
    // 发送消息
    sendMsg() {
      if (!this.msg) return;

      let params = {
        fromUserid: this.userInfo.id,
        msg: this.msg,
        msgType: "0",
        toUserid: this.msgUsr.id,
      };
      local.set("webscoketParams", JSON.stringify(params));
      this.$store.dispatch("senWebSocket", JSON.stringify(params));

      let obj = {
        fromUser: this.myInfo,
        fromUserid: this.userInfo.id,
        id: this.msgList.length - 1,
        msg: this.msg,
        msgRead: "0",
        msgType: "0",
        toUser: this.msgUsr,
        toUserid: this.msgUsr.id,
        msgTime: this.getTime(),
      };

      this.msgList.push(obj);
      this.list[this.activeIndex].msg = this.msg;
      this.list[this.activeIndex].time = this.getTime().split(" ")[0];
      this.list[this.activeIndex].msgTime = this.getTime();
      // 根据时间排序
      this.list = this.list.sort(
        (a, b) => new Date(b.msgTime) - new Date(a.msgTime)
      );

      this.scrollToBottom();
      this.activeIndex = 0;
      this.scrollToView("list0");
      this.msg = "";
    },
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        console.log("scrollHeight>>>", this.$refs.right);

        if (this.$refs.right) {
          this.$refs.right.scrollTop = this.$refs.right.scrollHeight;
        } else {
          setTimeout(() => {
            this.$refs.right.scrollTop = this.$refs.right.scrollHeight;
          }, 2000);
        }
      });
    },
    // 滚动到指定位置
    scrollToView(id) {
      this.$nextTick(() => {
        document.getElementById(id).scrollIntoView({
          behavior: "instant",
          block: "start",
          inline: "nearest",
        }); // 见下文
      });
    },
    // 获取当前时间
    getTime() {
      // 获取当前年月日时分秒
      // 获取当前日期和时间
      const now = new Date();
      // 获取年份
      const year = now.getFullYear();
      // 获取月份（注意：月份是从0开始的，1月是0，12月是11）
      const month = String(now.getMonth() + 1).padStart(2, "0");
      // 获取日期
      const day = String(now.getDate()).padStart(2, "0");
      // 获取小时
      const hours = String(now.getHours()).padStart(2, "0");
      // 获取分钟
      const minutes = String(now.getMinutes()).padStart(2, "0");
      // 获取秒数
      const seconds = String(now.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    /* 接收新消息 */
    // 删除本地存储中属于该聊天对象的数据
    delStorage(data) {
      if (!data) {
        data = local.get("newMsg") || [];
      }
      if (data.length == 0) return;

      /* 删除已经使用的数据 */
      const delIndexs = [];
      data.forEach((v, i) => {
        if (v.fromUserid == this.msgUsr.id) {
          delIndexs.push(i);
        }
      });
      delIndexs.forEach((v) => {
        data.splice(v, 1);
      });
      local.set("newMsg", data);
    },
    // 处理新消息
    handleGetNewMsg(data) {
      if (this.msgUsr.id == this.myInfo.id) {
        this.delStorage(data);
        // return;
      }

      /* 聊天窗口 */
      if (data && data.length > 0) {
        let temp = data.filter((v) => v.fromUserid == this.msgUsr.id);
        if (temp.length > 0) {
          temp.forEach((v) => {
            this.msgList.push(v);
            this.scrollToBottom();
          });
        }
        /* 删除已经使用的数据 */
        this.delStorage(data);
      }

      /* 列表 */
      if (data && data.length > 0) {
        /* 将data处理成需要的数据格式 */
        let newFromUserids = data.map((v) => v.fromUserid);
        newFromUserids = Array.from(new Set(newFromUserids)); // 去重
        newFromUserids = newFromUserids.map((v) => {
          return {
            id: v,
            num: 0,
            newMsg: {},
          };
        });
        newFromUserids.forEach((val) => {
          let newMsg = data.filter((v) => v.fromUserid == val.id);
          val.num = newMsg.length;
          let msg = newMsg[newMsg.length - 1];
          val.newMsg = { ...msg, time: msg.msgTime.split(" ")[0] };
        });
        /* 将list中目前已有的fromUserId筛选出来并组装成需要的数据格式 */
        const oldFromUserids = this.list.map((v, i) => {
          if (v.fromUserid == this.userInfo.id) {
            return {
              id: v.toUserid,
              msg: v,
              index: i,
            };
          } else {
            return {
              id: v.fromUserid,
              msg: v,
              index: i,
            };
          }
        });
        /* 对比list和新消息，id相等的数据进行num和msg的替换，不相等的则代表是新消息，保存到excludeData中和list进行合并 */
        let excludeData = [];
        newFromUserids.forEach((v) => {
          let temp = oldFromUserids.filter((val) => {
            return val.id == v.id;
          });
          if (temp.length > 0) {
            let obj = temp[0];
            this.list[obj.index] = {
              ...v.newMsg,
              avatarUrl: this.list[obj.index].avatarUrl,
              newNum: v.num,
            };
          } else {
            let avatarUrl = "";
            if (v.newMsg.fromUserid == this.userInfo.id) {
              avatarUrl =
                v.newMsg.toUser.headimgurl ||
                "https://www.zizhihelp.com/upload/zwtx.png";
            } else {
              avatarUrl =
                v.newMsg.fromUser.headimgurl ||
                "https://www.zizhihelp.com/upload/zwtx.png";
            }
            excludeData.push({
              ...v.newMsg,
              avatarUrl,
              newNum: v.num,
            });
          }
        });
        let list = [...this.list, ...excludeData];
        // 根据时间排序
        list.sort((a, b) => new Date(b.msgTime) - new Date(a.msgTime));
        this.list = list;
      }
    },
  },

  created() {
    this.getList(false);
    this.$bus.$on("newMsg", this.handleGetNewMsg);
  },
};
</script>

<style lang="less" scoped>
.im_wrapper {
  width: 100%;
  height: 100%;

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .im_box {
      display: flex;
      height: 600px;
      width: 1000px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 15px;

      .list_wrapper {
        flex: 3;
        padding: 10px 0px 0px 10px;
        background-color: #f7f8fa;
        border-radius: 15px 0px 0px 15px;
        display: flex;
        flex-direction: column;

        .search {
          margin-bottom: 10px;
          padding-right: 10px;
          position: relative;

          /deep/.el-input__inner {
            border-radius: 20px;
            border: none;
          }

          .search_suggest {
            position: absolute;
            z-index: 10;
            left: 0;
            top: 40px;
            max-height: 200px;
            width: calc(100% - 10px);
            background-color: #fff;
            border-radius: 6px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            overflow-y: scroll;

            .suggest_item {
              padding: 10px 15px;
              cursor: pointer;
              &:hover {
                background-color: #e6e9ed;
              }
            }
          }
        }

        .chat_list {
          flex: 1;
          background-color: #fff;
          overflow-y: scroll;

          .list_item {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            cursor: pointer;
            border-radius: 4px;

            .avatar {
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }

            .right_box {
              flex: 1;
              margin-left: 15px;
              font-size: 14px;

              .name_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #333;
                margin-bottom: 10px;

                .time {
                  color: #999;
                  font-size: 12px;
                  white-space: nowrap;
                  margin-left: 5px;
                }
              }

              .msg_box {
                font-size: 12px;
                color: #999;
              }
            }

            &:hover {
              background-color: #e6e9ed;
            }
          }

          .active {
            background-color: #e6e9ed;
          }
        }
      }

      .chat_wrapper {
        flex: 7;
        background-color: #f0f2f5;
        border-radius: 0px 15px 15px 0px;
        // padding: 20px;
        font-size: 14px;
        display: flex;
        flex-direction: column;

        .chat_name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          color: #333;
          padding: 20px;
          padding-bottom: 15px;
        }

        .msg_wrapper {
          flex: 1;
          overflow-y: scroll;
          border-top: 1px solid #dce0e5;
          border-bottom: 1px solid #dce0e5;
          padding: 0px 15px 0px;

          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #f0f2f5 !important;
          }

          .msg_item {
            .content_wrapper {
              display: flex;
              margin-bottom: 15px;

              .avatar {
                width: 45px;
                height: 45px;
                border-radius: 50%;
              }
            }

            .chat_content {
              flex: 1;
              .name_wrapper {
                font-size: 12px;
                color: #999;
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                .time {
                  margin-left: 10px;
                  white-space: nowrap;
                }
              }

              .msg {
                flex: 1;
                padding: 10px;
                background-color: #fff;
                border-radius: 6px;
                line-height: 1.4;
                width: fit-content;
                white-space: wrap;
                word-break: break-all;
                max-width: 500px;
              }
            }

            .chat_content_right {
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .name_wrapper {
                justify-content: flex-end;
              }
            }
          }
        }

        .input_wrapper {
          /deep/.el-textarea__inner {
            background-color: #f0f2f5;
            // background-color: red;
            border-radius: 0px;
            border: none;
            margin: 10px 0px;

            &::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
            }
            &::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 10px;
              background: #e1e2e5;
            }
            &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              background: #f0f2f5;
              border-radius: 10px;
            }
          }

          /deep/.el-input__count {
            background-color: #f0f2f5;
          }

          .send_btn {
            display: inline-block;
            text-align: right;
            background-color: #1d89e3;
            color: #fff;
            padding: 8px 15px 8px 18px;
            text-align: center;
            border-radius: 16px;
            letter-spacing: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
